export enum FEATURES {
  COMPANY = 'Company',
  COMPANY_LINE = 'CompanyLine',
  PLACE = 'Place',
  ROUTE = 'Route',
  SERVICE = 'RouteService',
  SEGMENT = 'Segment',
  TRIP = 'Trip',
  BOOKING = 'Booking',
  SEAT = 'Seat',
  PAYMENT = 'Payment',
  EMAIL = 'Email',
  SMS = 'SMS',
  USER = 'User',
  POLICY = 'Policy',
  SITE = 'Site',
  VEHICLE = 'Vehicle',
  VEHICLE_TEMPLATE = 'VehicleTemplate',
  BOOKING_CLASS = 'BookingClass',
  SEASON_EVENT = 'SeasonEvent',
  FARE_FAMILY = 'FareFamily',
  FARE_RULE = 'FareRule',
  CAMPAIGN = 'Campaign',
  COUPON = 'Coupon',
  REPORT = 'Report',
  CHANGES = 'Change',
  PASSENGER_TYPES = 'PassengerType',
  PASSENGER_PROPERTIES = 'PassengerProperty',
  SCHEDULER = 'ScheduledCommand',
  DASHBOARD = 'Dashboard',
  ITEM = 'Item',
  SALE = 'Sale',
  INVENTORY = 'Inventory',
  BASE = 'Base',
  WAREHOUSE = 'Warehouse',
  CART = 'Cart',
  ORDER = 'Order',
  PASSENGERS = 'Passengers',
  ANCILLARIES = 'Ancillary',
  INVENTORY_RULE = 'InventoryRule',
  LOYALTY_CAMPAIGN = 'LoyaltyCampaign',
  LOYALTY_REWARD = 'LoyaltyReward',
  WALLET = 'Wallet',
  KREDIT = 'Kredit',
  BUSINESS_SITE = 'BusinessSite',
  BUSINESS_CLIENT = 'BusinessClient',
  BUSINESS_CUSTOMER = 'BusinessCustomer',
  PARTNER = 'Partner',
  DRIVER = 'Driver',
  CORPORATE_KREDITS = 'CorporateKredits',
  PARTNER_COST = 'PartnerCost',
  OPERATIONAL_COSTS = 'OperationalCosts',
  BUSINESS_CLIENT_USER = 'BusinessClientUser',
  USER_ACCOUNT = 'UserAccount',
  ROUTING_PROJECT = 'RoutingProject',
  PARTNERS_PAYMENT_SUMMARY = 'PartnersPaymentSummary'
}

export enum PERMISSIONS {
  ALL_ACCESS = 'allAccess',
  READ = 'read',
  ON_SITE = 'onSite',
  CANCEL = 'cancel',
  BOARDING = 'boarding',
  BOOKINGS_READ = 'bookingsRead',
  BOOKINGS_DASHBOARD_READ = 'bookingsDashboardRead',
  DEMAND_READ = 'demandRead',
  OFFER_READ = 'offerRead',
  USERS_READ = 'usersRead',
  COMPETITOR_READ = 'competitorRead',
  TICKETS_READ = 'ticketsRead',
  OCCUPANCY_READ = 'occupancyRead',
  BUSINESS_BOOKINGS_READ = 'businessBookingsRead',
  SCHEDULE_COMMAND = 'scheduleCommand',
  CHANGE_SCHEDULED_COMMAND = 'changeScheduledCommand',
  CANCEL_SCHEDULED_COMMAND = 'cancelScheduledCommand',
  DRAWER_BOOKINGS_READ = 'drawerBookingsRead',
  DRAWER_SALES_READ = 'drawerSalesRead',
  DRAWER_KREDIT_SALES_READ = 'drawerKreditSalesRead',
  SALES_READ = 'salesRead',
  EDIT = 'edit',
  ACTIVATE = 'activate',
  DEACTIVATE = 'deactivate',
  MOVE_TRIP = 'moveTrip',
  COMPANY_INSURANCE_SETTINGS_READ = 'companyInsuranceSettingsRead',
  TRIP_BOOKINGS_READ = 'TripBookingsRead',
  CHANGE_SETTINGS = 'changeSettings',
  WASTE = 'waste',
  LATE_CHECKOUT_BOOKING_TICKET = 'lateCheckOutBookingTicket',
  SEND_EMAIL_BOOKING = 'sendEmailBooking',
  APPLY_TICKETS_INSURANCE = 'applyTicketsInsurance',
  CANCEL_TICKETS_INSURANCE = 'cancelTicketsInsurance',
  REGISTER_BOOKING_SUPPORT_INTERACTION = 'registerBookingSupportInteraction',
  CONFIRM_BOOKING_PAYMENT_FROM_ADMIN = 'confirmBookingPaymentFromAdmin',
  CHANGE_TRIP_USER_IDS = 'changeTripUserIDs',
  TOGGLE_SALE_ACTIVATION = 'toggleSaleActivation',
  TOGGLE_ACTIVACTION = 'toggleActivation',
  ENABLE_PASSENGER_TYPE = 'enablePassengerType',
  DISABLE_PASSENGER_TYPE = 'disablePassengerType',
  ENABLE_PASSENGER_PROPERTY = 'enablePassengerProperty',
  DISABLE_PASSENGER_PROPERTY = 'disablePassengerProperty',
  RESEND_COUPONS = 'ResendCoupons',
  CREATE_TRIP = 'createTrip',
  DEACTIVATE_CAMPAIGN_SUBCAMPAIGN_COUPON = 'deactivateCampaignSubcampaignCoupon',
  RESEND_COUPON = 'resendCoupon',
  INCIDENCE_SUBCAMPAIGNS_READ = 'incidenceSubcampaignsRead',
  DB_SUBCAMPAIGNS_READ = 'dataBaseSubcampaignsRead',
  CREATE_SUBCAMPAIGN = 'createSubcampaign',
  DEPOSIT_KREDIT_CORPORATE_FUNDS_FROM_ADMIN = 'DepositKreditCorporateFundsFromAdmin',
  CHANGE_CONFIRMATION_MODALITIES = 'changeConfirmationModalities',
  REGISTER_BOOKING_TAX_INVOICED_FROM_ADMIN = 'registerBookingTaxInvoicedFromAdmin',
  ASSIGN_PRICE = 'assignPrice',
  CHANGE_DRIVER = 'changeDriver',
  CHANGE_ITINERARY = 'changeItinerary',
  CHANGE_PAST_VEHICLE = 'changePastVehicle',
  CHANGE_ROUTE = 'changeRoute',
  CHANGE_VEHICLE = 'changeVehicle'
}
