import { ReactElement, Suspense, lazy } from 'react'
import { Navigate } from 'react-router-dom'

import { PrefetchLoader } from '@10xLabs/react-web/components/PrefetchLoader'
import { AppContent } from 'components/v2/Content'

const PaymentsSummaryView = lazy(
  (): Promise<{ default: () => ReactElement }> =>
    import(/* webpackChunkName: "payments-summary" */ /* webpackPrefetch: true */ './views/Index')
)
const NewPaymentSummaryView = lazy(
  (): Promise<{ default: () => ReactElement }> =>
    import(/* webpackChunkName: "new-payment-summary" */ /* webpackPrefetch: true */ './views/New')
)
const EditPaymentSummaryView = lazy(
  (): Promise<{ default: () => ReactElement }> =>
    import(/* webpackChunkName: "edit-payment-summary" */ /* webpackPrefetch: true */ './views/Edit')
)

export const PaymentSummaryRoutes = {
  path: 'partners/payment-summary',
  children: [
    {
      index: true,
      element: (
        <AppContent>
          <Suspense fallback={<PrefetchLoader />}>
            <PaymentsSummaryView />
          </Suspense>
        </AppContent>
      )
    },
    {
      path: 'new',
      element: (
        <AppContent>
          <Suspense fallback={<PrefetchLoader />}>
            <NewPaymentSummaryView />
          </Suspense>
        </AppContent>
      )
    },
    {
      path: ':paymentSummaryId',
      element: (
        <AppContent>
          <Suspense fallback={<PrefetchLoader />}>
            <EditPaymentSummaryView />
          </Suspense>
        </AppContent>
      )
    },
    {
      path: '*',
      element: <Navigate to="/payment-summary/new" replace />
    }
  ]
}
