import { ReactElement, Suspense, lazy } from 'react'
import { Navigate } from 'react-router-dom'

import { PrefetchLoader } from '@10xLabs/react-web/components/PrefetchLoader'
import { AppContent } from 'components/v2/Content'

const NewOperationalCostsView = lazy(
  (): Promise<{ default: () => ReactElement }> =>
    import(/* webpackChunkName: "operational-costs" */ /* webpackPrefetch: true */ '../OperationalCosts/views/New')
)

export const OperationalCostsRoutes = {
  path: '/operational-costs',
  children: [
    {
      path: 'new',
      element: (
        <AppContent>
          <Suspense fallback={<PrefetchLoader />}>
            <NewOperationalCostsView />
          </Suspense>
        </AppContent>
      )
    },
    {
      path: '*',
      element: <Navigate to="/operational-costs/" replace />
    }
  ]
}
